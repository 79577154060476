import React from 'react';
import './2Home.css';
import homeImage from '../assets/img/crtezi/KuceDujeSareno.png';

function Home() {
    return (
        <div className="home-container">
            <img src={homeImage} alt="View of Split, Croatia" className="home-image"/>
            <div className="home-overlay-text">
                <h1>Welcome to <span className='highlight'>Mali Ante</span>!</h1>
                <p>Welcome to the charming Mali Ante, a cute and cozy apartment in Split, Croatia, offering a peaceful retreat in the heart of the historic Veli Varoš neighborhood. This inviting, top-floor apartment boasts a charming terrace with picturesque views of the Old Town, including St. Domnius' Cathedral. Perfectly situated, it's just minutes from major attractions and the peaceful Marjan Forest Park, all while nestled in a tranquil neighborhood of traditional stone houses.</p>
            </div>
        </div>
    );
}

export default Home;