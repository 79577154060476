import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import './9Connect.css';
import ConnectModal from './9ConnectModal';

function Connect() {
    const form = useRef();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();
    setModalMessage('Sending...');
    setModalOpen(true);

    emailjs
    .sendForm(
        process.env.REACT_APP_SERVICE_ID, 
        process.env.REACT_APP_TEMPLATE_ID, 
        form.current, 
        { publicKey: process.env.REACT_APP_PUBLIC_KEY }
      )
      .then(
        () => {
            console.log('SUCCESS!');
            setModalMessage('Message sent successfully.');
            e.target.reset();
        },
        (error) => {
          console.log('FAILED...', error.text);
          setModalMessage('Something went wrong, please try again later.');
        },
      );
  };

    return (
        <div className="connect-container">
            <ConnectModal isOpen={modalOpen} close={() => setModalOpen(false)} message={modalMessage} />
            <h1>Let's <span className="highlight">Connect</span>.</h1>
            <form ref={form} onSubmit={sendEmail}>
                <p>Whether you have questions about availability, specific dates, or any special requests, we are here to help. Contact us at any time to ensure your rental experience is as seamless and enjoyable as possible. Please make sure to provide a correct email address so that we can respond to your inquiries promptly.</p>
                <div className="connect-form-group">
                    <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        required
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Email address"
                        required
                    />
                </div>
                <textarea
                    name="message"
                    placeholder="Message"
                    required
                />
                <button type="submit" className="connect-button">Submit</button>
            </form>
        </div>
    );
}

export default Connect;