import React from 'react';

const ConnectModal = ({ isOpen, close, message }) => {
    if (!isOpen) return null;

    return (
        <div className="message-modal-overlay" onClick={close}>
            <div className="message-modal-content" onClick={e => e.stopPropagation()}>
                <span className="message-modal-close" onClick={close}>&times;</span>
                <div className="message-modal-body">{message}</div>
            </div>
        </div>
    );
}

export default ConnectModal;