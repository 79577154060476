import React, { useState, useEffect, useCallback, useRef } from 'react';
import './1NavBar.css';
import logo from '../assets/img/crtezi/LogoPlaviNaBilo.png';
import { FaBars } from 'react-icons/fa';

function NavBar() {
    const [activeSection, setActiveSection] = useState('home');
    const [isNavVisible, setIsNavVisible] = useState(false);
    const navbarRef = useRef(null);

    const handleScroll = useCallback(() => {
        const sections = ['home', 'photos', 'reviews', 'host', 'features', 'map', 'amenities', 'connect'];
        const scrollY = window.pageYOffset + 100;

        const currentSection = sections.find(section => {
            const el = document.getElementById(section);
            if (!el) return false;
            const top = el.offsetTop;
            const height = el.offsetHeight;
            return scrollY >= top && scrollY < top + height;
        });

        if (currentSection && currentSection !== activeSection) {
            setActiveSection(currentSection);
        }
    }, [activeSection]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (navbarRef.current && !navbarRef.current.contains(event.target)) {
                setIsNavVisible(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const toggleNav = () => {
        setIsNavVisible(!isNavVisible);
    };

    return (
        <div>
            <header className='navbar-container' ref={navbarRef}>
                <div className='navbar-left'>
                    <a href="#home" className={activeSection === 'home' ? 'active' : ''}>
                        <img src={logo} alt="Logo" className='navbar-logo'/>
                    </a>
                    <h1 className='navbar-title'>Mali Ante apartment Split</h1>
                </div>
                <nav className={`navbar-nav ${isNavVisible ? 'show' : ''}`}>
                    <a href="#home" className={activeSection === 'home' ? 'active' : ''}>Home</a>
                    <a href="#photos" className={activeSection === 'photos' ? 'active' : ''}>Photos</a>
                    <a href="#reviews" className={activeSection === 'reviews' ? 'active' : ''}>Reviews</a>
                    <a href="#host" className={activeSection === 'host' ? 'active' : ''}>Host</a>
                    <a href="#features" className={activeSection === 'features' ? 'active' : ''}>Features</a>
                    <a href="#map" className={activeSection === 'map' ? 'active' : ''}>Map</a>
                    <a href="#amenities" className={activeSection === 'amenities' ? 'active' : ''}>Amenities</a>
                </nav>
                <button className="navbar-toggle" onClick={toggleNav}>
                    <FaBars />
                </button>
                <button className={`talk-button ${activeSection === 'connect' ? 'active' : ''}`}>
                    <a href="#connect">Let's connect</a>
                </button>
            </header>
        </div>
    );
}

export default NavBar;