import React, { useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from './components/1NavBar';
import Home from './components/2Home';
import Photos from './components/3Photos';
import Reviews from './components/4Reviews';
import Host from './components/5Host';
import Features from './components/6Features';
import Map from './components/7Map';
import Amenities from './components/8Amenities';
import Connect from './components/9Connect';
import Footer from './components/10Footer';

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <NavBar />
      <div id="home"><Home /></div>
      <div id="photos"><Photos /></div>
      <div id="reviews"><Reviews /></div>
      <div id="host"><Host /></div>
      <div id="features"><Features /></div>
      <div id="map"><Map /></div>
      <div id="amenities"><Amenities /></div>
      <div id="connect"><Connect /></div>
      <Footer />
    </div>
  );
}

export default App;