import React, { useState } from 'react';
import './5Host.css';
import tanja from '../assets/img/crtezi/Host3.png';
import badge from '../assets/img/icons/badge.jpg';

function Host() {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleText = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="host-container">
            <h1>Meet the <span className="highlight">Host</span>.</h1>

            <div className='host-intro'>
                <img src={badge} alt="Badge" className="badge-image"/>
                <p>Tanja has been an exceptional host for over 11 years, dedicating herself to providing outstanding hospitality. With over 450 positive reviews, she has consistently demonstrated her commitment to guests. Tanja ensures a seamless communication experience, maintaining a 100% response rate and usually addressing all guest inquiries and needs within an hour.</p>
            </div>
            <img src={tanja} alt="Tanja" className="host-tanja-image"/>
            <div className='host-text'>
                <p className='host-text-naslov'>"I am Tanja."</p>
                {isExpanded && (
                    <p>My friends describe me as a friendly, cheerful, helpful and sensible person. My family is most important to me. My friends are the ones who make my life richer. I spend a lot of time in the nature and do my best to live green. I enjoy travelling. I love meeting new people and cultures. My life motto is "Never impose on others what you would not choose for yourself". I will do my best to make your stay in our beautiful town unforgettable.</p>
                )}
                <button onClick={toggleText} className="host-button">
                    {isExpanded ? 'Read less' : 'Read more about Tanja'}
                </button>
            </div>
            <p className='host-zadnje'></p>
        </div>
    );
}

export default Host;