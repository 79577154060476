import React from 'react';
import './10Footer.css';
import logo from '../assets/img/crtezi/LogoBiliNaCrno.png';

function Footer() {
    return (
        <footer className='footer-container'>
            <div className='footer-left'>
                <img src={logo} alt="Logo" className='footer-logo'/>
            </div>
            <div className="footer-nav">
                <p className='footer-copyright'>© Mali Ante apartment 2024. All rights reserved.</p>
                <p className='footer-adress'>Vijugasta 33, 21000 Split, Croatia</p>
                <p><a href="mailto:tdjelavic@yahoo.com" className="footer-email">malianteapartment@gmail.com</a></p>
            </div>
            <div className="footer-right">
            </div>
        </footer>
    );
}

export default Footer;