import React from 'react';

function PhotosModal({ isOpen, close, title, images }) {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={close}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <span className="close-button" onClick={close}>&times;</span>
                <div className="modal-title">{title}</div>
                {images.map((img, index) => (
                    <img key={index} src={img} alt={`Detail ${index + 1}`} style={{ marginBottom: '20px' }} />
                ))}
            </div>
        </div>
    );
}

export default PhotosModal;