import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './8Amenities.css';
import amBathroom from '../assets/img/amenities/Bathroom.png';
import amKitchen from '../assets/img/amenities/Kitchen.png';
import amLaundry from '../assets/img/amenities/Laundry.png';
import amOffice from '../assets/img/amenities/Office.png';
import amOther from '../assets/img/amenities/Other.png';
import amEntertainment from '../assets/img/amenities/Entertainment.png';
import amHeatingCooling from '../assets/img/amenities/HeatingCooling.png';
import amBedroom from '../assets/img/amenities/Bedroom.png';

function Amenities() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 400,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: false,
        cssEase: "linear",
        arrows: true,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 360,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="amenities-container">
            <h1>Check Out <span className="highlight">Amenities</span>.</h1>
            <div className="amenities-carousel-container">
                <Slider {...settings}>
                    <div className="amenities-card">
                        <img src={amBathroom} alt="icon" className='amenities-icon'/>
                        <p className="amenities-room-title">Bathroom</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Hair dryer</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Soap</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Shower gel</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Shampoo</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Towels</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Toilet paper</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Cleaning products</p>
                    </div>
                    <div className="amenities-card">
                        <img src={amLaundry} alt="icon" className='amenities-icon'/>
                        <p className="amenities-room-title">Laundry</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Washing machine</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Laundry detergent</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Iron</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Hangers</p>
                    </div>
                    <div className="amenities-card">
                        <img src={amBedroom} alt="icon" className='amenities-icon'/>
                        <p className="amenities-room-title">Bedroom</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Bed sheets</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Extra pillows and blankets</p>
                    </div>
                    <div className="amenities-card">
                        <img src={amKitchen} alt="icon" className='amenities-icon'/>
                        <p className="amenities-room-title">Kitchen</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Refrigerator</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Freezer</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Electric stove</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Oven</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Microwave</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Hot water kettle</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Coffee maker</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Toaster</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Pots and pans</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Dishes and silverware</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Basic spices and oils</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Dishwasher</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Dish detergent</p>
                    </div>
                    <div className="amenities-card">
                        <img src={amOffice} alt="icon" className='amenities-icon'/>
                        <p className="amenities-room-title">Office</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Wifi</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Dedicated workspace</p>
                    </div>
                    <div className="amenities-card">
                        <img src={amEntertainment} alt="icon" className='amenities-icon'/>
                        <p className="amenities-room-title">Entertainment</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Cable TV</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Books</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Board games</p>
                    </div>
                    <div className="amenities-card">
                        <img src={amHeatingCooling} alt="icon" className='amenities-icon'/>
                        <p className="amenities-room-title">Heating and cooling</p>
                        <p className="amenities-item"><span className='highlight'>•</span> AC</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Heating</p>
                    </div>
                    <div className="amenities-card">
                        <img src={amOther} alt="icon" className='amenities-icon'/>
                        <p className="amenities-room-title">Other</p>
                        <p className="amenities-item"><span className='highlight'>•</span> First aid kit</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Umbrellas</p>
                        <p className="amenities-item"><span className='highlight'>•</span> High chair - available upon request</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Free street parking</p>
                        <p className="amenities-item"><span className='highlight'>•</span> Paid parking off premises</p>
                    </div>
                </Slider>
            </div>
        </div>
    );
}

export default Amenities;