import React, { useState } from 'react';
import './6Features.css';
import PhotosModal from './3PhotosModal';
import center from '../assets/img/features/center.png';
import center1 from '../assets/img/features/center1.jpg';
import center2 from '../assets/img/features/center2.jpg';
import center3 from '../assets/img/features/center3.jpg';
import center4 from '../assets/img/features/center4.jpg';
import center5 from '../assets/img/features/center5.jpg';
import center6 from '../assets/img/features/center6.jpg';
import marjan from '../assets/img/features/marjan.png';
import marjan1 from '../assets/img/features/marjan1.jpg';
import marjan2 from '../assets/img/features/marjan2.jpg';
import marjan3 from '../assets/img/features/marjan3.jpg';
import marjan4 from '../assets/img/features/marjan4.jpg';
import marjan5 from '../assets/img/features/marjan5.jpg';
import marjan6 from '../assets/img/features/marjan6.jpg';
import local from '../assets/img/features/local.png';
import local1 from '../assets/img/features/local1.jpg';
import local2 from '../assets/img/features/local2.jpg';
import local3 from '../assets/img/features/local3.jpg';
import local4 from '../assets/img/features/local4.jpg';
import local5 from '../assets/img/features/local5.jpg';
import local6 from '../assets/img/features/local6.jpg';

function Features() {
    const [isExpanded1, setIsExpanded1] = useState(false);

    const toggleText1 = () => {
        setIsExpanded1(!isExpanded1);
    };
    const [isExpanded2, setIsExpanded2] = useState(false);

    const toggleText2 = () => {
        setIsExpanded2(!isExpanded2);
    };
    const [isExpanded3, setIsExpanded3] = useState(false);

    const toggleText3 = () => {
        setIsExpanded3(!isExpanded3);
    };

    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalImages, setModalImages] = useState([]);

    const handleImageClick = (title, images) => {
        setModalOpen(true);
        setModalTitle(title);
        setModalImages(images);
    };
    
    return (
        <div className="features-container">
            <h1>Explore location's best <span className="highlight">Features</span>.</h1>
            <div className='features-card'>
                <img src={center} alt="View of Split, Croatia" className="features-image" onClick={() => handleImageClick("", [center1, center2, center3, center4, center5, center6])}/>
                <div className="features-text">
                    <p className='features-text-naslov'>Minutes from Split's city centre</p>
                    <p>Just a five-minute walk from the apartment, find yourself on the bustling Riva and within the ancient walls of Emperor Diocletian's Palace.</p>
                    {isExpanded1 && (
                            <p>Explore the heart of Split where the past meets the present. Frequent visits allow you to experience its beauty at different times of the day, echoing Diocletian's own sentiment: "<span className="highlight">If you saw where I settled, you would never ask me to come back.</span>" This proximity to the city center ensures that the best of Split is always at your fingertips.</p>
                        )}
                        <button onClick={toggleText1} className="features-button">
                            {isExpanded1 ? 'Read less' : 'Read more'}
                        </button>
                </div>
            </div>
            <div className='features-card'>
                <div className="features-text">
                    <p className='features-text-naslov'>Live like a local</p>
                    <p>Immerse yourself in the timeless charm of Veli Varoš, one of Split's oldest neighborhoods.</p>
                    {isExpanded2 && (
                            <p>Nestled within the city, this district is renowned for its winding alleys, unique architecture, and traditional stone houses that reflect its rich history. As you stroll through these historic streets, you'll witness the daily lives of local residents and truly feel part of a vibrant community. This area not only offers a picturesque setting but also a genuine taste of local culture and history.</p>
                        )}
                        <button onClick={toggleText2} className="features-button">
                            {isExpanded2 ? 'Read less' : 'Read more'}
                        </button>
                </div>
                <img src={local} alt="View of Split, Croatia" className="features-image" onClick={() => handleImageClick("", [local1, local2, local3, local4, local5, local6])}/>
            </div>
            <div className='features-card'>
            <img src={marjan} alt="View of Split, Croatia" className="features-image" onClick={() => handleImageClick("", [marjan1, marjan2, marjan3, marjan4, marjan5, marjan6])}/>
            <div className="features-text">
                    <p className='features-text-naslov'>Easy access to Marjan Forest Park</p>
                    <p>Elevate your stay by venturing just a few minutes upward to Marjan hill, Split's recreational zone.</p>
                    {isExpanded3 && (
                            <p>Ideal for walking, running, or cycling, Marjan’s coastal trail stretches approximately 6.5 km and offers breathtaking views. Whether you're up for hiking to the summit or enjoying a refreshing swim in some of the clearest waters around Split, Marjan Hill provides a perfect backdrop for a day filled with activity or relaxation.</p>
                        )}
                        <button onClick={toggleText3} className="features-button">
                            {isExpanded3 ? 'Read less' : 'Read more'}
                        </button>
                </div>
            </div>
            <PhotosModal
                isOpen={modalOpen}
                close={() => setModalOpen(false)}
                title={modalTitle}
                images={modalImages}
            />
        </div>
    );
}

export default Features;