import React from 'react';
import './7Map.css';

function Map() {
    const apiKey = process.env.REACT_APP_MAPS_API_KEY;

    return (
        <div className="map-container">
            <h1>View on the <span className="highlight">Map</span>.</h1>
            <iframe
                title="Google Maps - Split, Croatia"
                src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27529.152919066502!2d16.429337385587985!3d43.50972510869097!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13355df9612b8671%3A0x720d523532e3e90d!2sVijugasta%20ul.%2033%2C%2021000%2C%20Split!5e0!3m2!1sen!2shr!4v1720784498414!5m2!1sen!2shr&key=${apiKey}`}
                width="815"
                height="450"
                style={{ border:0, outline: 'none' }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
        </div>
    );
}

export default Map;