import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import './3Photos.css';
import PhotosModal from './3PhotosModal';
import balkon1 from '../assets/img/slikeapartmanavodoravno/balkonpogled.jpg';
import balkon2 from '../assets/img/slikeapartmanavodoravno/balkonbongovila.jpg';
import balkon3 from '../assets/img/slikeapartmanavodoravno/balkonbongovila2.jpg';
import dnevni1 from '../assets/img/slikeapartmanavodoravno/dnevni.jpg';
import dnevni2 from '../assets/img/slikeapartmanavodoravno/dnevni2.jpg';
import soba1 from '../assets/img/slikeapartmanavodoravno/soba.jpg';
import kuhinja1 from '../assets/img/slikeapartmanavodoravno/kuhinja.jpg';
import kuhinja2 from '../assets/img/slikeapartmanavodoravno/kuhinjahodnik.jpg';
import wc1 from '../assets/img/slikeapartmanavodoravno/wc.jpg';

function Photos() {
    const settings = {
        dots: false,
        infinite: false,
        speed: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        cssEase: "linear",
        initialSlide: 0,
    };

    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalImages, setModalImages] = useState([]);

    const handleImageClick = (title, images) => {
        setModalOpen(true);
        setModalTitle(title);
        setModalImages(images);
    };

    return (
        <div className="photos-container">
            <h1>Have a look at the <span className="highlight">Photos</span>.</h1>
            <div className="carousel-container">
                <Slider {...settings}>
                    <div className="image-container" onClick={() => handleImageClick("Cozy Living Area", [dnevni1, dnevni2])}>
                        <img src={dnevni1} alt="Living" className="carousel-image"/>
                        <div className="image-description">
                            <h2 className='apartmentImg-description-title'>Cozy Living Area</h2>
                            <p className='apartmentImg-description-text'>Unwind in this cozy living space that blends modern comfort with Mediterranean charm, featuring a soft sofa, decorative pillows, and a serene atmosphere for relaxation.</p>
                            <div className="image-hover-indicator">
                                <FontAwesomeIcon icon={faPlusCircle} className="icon-spacing" />Click for more
                            </div>
                        </div>
                    </div>
                    <div className="image-container" onClick={() => handleImageClick("Sleep Easy Bedroom", [soba1])}>
                        <img src={soba1} alt="Bedroom" className="carousel-image"/>
                        <div className="image-description">
                            <h2 className='apartmentImg-description-title'>Sleep Easy Bedroom</h2>
                            <p className='apartmentImg-description-text'>Escape to this calm and collected bedroom designed with relaxation in mind. Featuring a plush double bed, clean lines, and soothing decor, it provides a peaceful haven after a day of exploring Split.</p>
                            <div className="image-hover-indicator">
                                <FontAwesomeIcon icon={faPlusCircle} className="icon-spacing" />Click for more
                            </div>
                        </div>        
                    </div>
                    <div className="image-container" onClick={() => handleImageClick("Charming Kitchen and Dining Area", [kuhinja1, kuhinja2])}>
                        <img src={kuhinja1} alt="Kitchen" className="carousel-image"/>
                        <div className="image-description">
                            <h2 className='apartmentImg-description-title'>Charming Kitchen and Dining Area</h2>
                            <p className='apartmentImg-description-text'>This fully equipped kitchen boasts Mediterranean flair and modern appliances, complemented by a quaint dining area featuring a classic wooden table, comfortable seating for four, and a bright ambiance perfect for dining in.</p>
                            <div className="image-hover-indicator">
                                <FontAwesomeIcon icon={faPlusCircle} className="icon-spacing" />Click for more
                            </div>
                        </div>                   
                    </div>
                    <div className="image-container" onClick={() => handleImageClick("Sleek and Modern Bathroom", [wc1])}>
                        <img src={wc1} alt="Bathroom" className="carousel-image"/>
                        <div className="image-description">
                            <h2 className='apartmentImg-description-title'>Sleek and Modern Bathroom</h2>
                            <p className='apartmentImg-description-text'>Experience convenience in this streamlined bathroom, equipped with a contemporary shower, essential amenities, and a washer. Fresh, clean towels are readily available for a refreshing stay.</p>
                            <div className="image-hover-indicator">
                                <FontAwesomeIcon icon={faPlusCircle} className="icon-spacing" />Click for more
                            </div>
                        </div>             
                    </div>
                    <div className="image-container" onClick={() => handleImageClick("Old Town Rooftop Terrace", [balkon1, balkon2, balkon3])}>
                        <img src={balkon1} alt="Balcony" className="carousel-image"/>
                        <div className="image-description">
                            <h2 className='apartmentImg-description-title'>Old Town Rooftop Terrace</h2>
                            <p className='apartmentImg-description-text'>Enjoy the splendid views of Split's old town from this delightful rooftop terrace. It's a perfect spot for soaking up the sun or enjoying a quiet evening under the stars.</p>
                            <div className="image-hover-indicator">
                                <FontAwesomeIcon icon={faPlusCircle} className="icon-spacing" />Click for more
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
            <PhotosModal
                isOpen={modalOpen}
                close={() => setModalOpen(false)}
                title={modalTitle}
                images={modalImages}
            />
        </div>
    );
}

export default Photos;