import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './4Reviews.css';
import quotations from '../assets/img/icons/quotations.png';
import stars from '../assets/img/icons/stars.jpg';

function Reviews() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 400,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        cssEase: "linear",
        arrows: true,
        initialSlide: 2,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="reviews-container">
            <h1>Read our guests <span className="highlight">Reviews</span>.</h1>
            <div className="reviews-carousel-container">
                <Slider {...settings}>
                    <div className="review-card">
                        <img src={quotations} alt="Quotations" className='review-quotations'/>
                        <p className="review-text">Very convenient and completely furnished apartment. Quietly located with a beautiful balcony for breakfast and nightcap. In a few minutes you are in the lively center of Split and also the beautiful park is easily accessible. Tanja is an extremely pleasant host and easily accessible. She gives valuable tips for great restaurants and things to do.</p>
                        <p className="review-author">Dieter</p>
                        <div className="review-stars-date">
                            <img src={stars} alt="Stars" className='review-stars'/>
                            <p className="review-date">June 2024</p>
                        </div>
                    </div>
                    <div className="review-card">
                        <img src={quotations} alt="Quotations" className='review-quotations'/>
                        <p className="review-text">Tanja was an amazing host, she welcomed us and gave lots of insightful infornation. The apartment was fantastic, in a very central location within walking distance to Split whilst also being in a very peaceful location. Would highly recommend a stay at Tanja's place.</p>
                        <p className="review-author">Michelle</p>
                        <div className="review-stars-date">
                            <img src={stars} alt="Stars" className='review-stars'/>
                            <p className="review-date">June 2024</p>
                        </div>
                    </div>
                    <div className="review-card">
                        <img src={quotations} alt="Quotations" className='review-quotations'/>
                        <p className="review-text">Our stay in Split was great and Tanja’s apartment was a big part of making our stay so enjoyable. The apartment was very clean and tidy and Tanja was very welcoming when we arrived. She stayed in touch throughout our stay. The location was terrific. Close to everything but far enough away that it was quiet. I thoroughly recommend this apartment. <span className='smajlici'>😊</span></p>
                        <p className="review-author">Sophie</p>
                        <div className="review-stars-date">
                            <img src={stars} alt="Stars" className='review-stars'/>
                            <p className="review-date">May 2024</p>
                        </div>
                    </div>
                    <div className="review-card">
                        <img src={quotations} alt="Quotations" className='review-quotations'/>
                        <p className="review-text">What a beautiful haven tucked away in beautiful Split. But near enough to get to all the delights of the city. The apartment is spotlessly clean and Tanja is a lovely helpful host. I will definitely be returning to this hidden gem</p>
                        <p className="review-author">Jacqueline</p>
                        <div className="review-stars-date">
                            <img src={stars} alt="Stars" className='review-stars'/>
                            <p className="review-date">May 2024</p>
                        </div>
                    </div>
                    <div className="review-card">
                        <img src={quotations} alt="Quotations" className='review-quotations'/>
                        <p className="review-text">This is the best Airbnb I have ever stayed in. It easily became two rooms when privacy needed. The kitchen has cookware that can actually be used to cook a meal, which we did. Coffee maker and toaster, even a couple beers and water in the fridge upon arrival. Beds very comfy, shower hot, plenty of towels. Tanja met us and gave a tour showing where things were, how to work TV and washer (YES a washer!!) restaurant suggestions, full and half day trip suggestions, where the supermarket, green market and bakery are. Tanja was always available for questions and responded within minutes of sending the message. The location is perfect for exploring Old Town Split. Minutes to waterfront, palace, great restaurants, and free parking on the street - but you may spend a little time before finding a spot - just like the locals. Rent a small car, and parking on the sidewalks is OK. Very happy with Tanjas place. Hope to return</p>
                        <p className="review-author">Robert</p>
                        <div className="review-stars-date">
                            <img src={stars} alt="Stars" className='review-stars'/>
                            <p className="review-date">October 2023</p>
                        </div>
                    </div>
                    <div className="review-card">
                        <img src={quotations} alt="Quotations" className='review-quotations'/>
                        <p className="review-text">This place was a perfect home base for split! Tanja was wonderful and provided great recommendations. The apartment was quiet, easily walkable, had air conditioning (!!) and a washing machine. We loved the balcony!</p>
                        <p className="review-author">Carly</p>
                        <div className="review-stars-date">
                            <img src={stars} alt="Stars" className='review-stars'/>
                            <p className="review-date">September 2023</p>
                        </div>
                    </div>
                    <div className="review-card">
                        <img src={quotations} alt="Quotations" className='review-quotations'/>
                        <p className="review-text">Tanja’s place was quaint, comfortable, and very clean. We were close to everything we needed while also being out of the noise of downtown. Tanja was a wonderful host!</p>
                        <p className="review-author">Elise</p>
                        <div className="review-stars-date">
                            <img src={stars} alt="Stars" className='review-stars'/>
                            <p className="review-date">August 2023</p>
                        </div>
                    </div>
                    <div className="review-card">
                        <img src={quotations} alt="Quotations" className='review-quotations'/>
                        <p className="review-text">Loved our stay in split! The apartment is very comfortable and in a good location. It’s in a quiet area but still a short walk to the main shops. Tanja was also a great host. I was feeling unwell during the trip and she was very kind and responsive in giving me advice. Highly recommend!</p>
                        <p className="review-author">Isabella</p>
                        <div className="review-stars-date">
                            <img src={stars} alt="Stars" className='review-stars'/>
                            <p className="review-date">August 2023</p>
                        </div>
                    </div>
                    <div className="review-card">
                        <img src={quotations} alt="Quotations" className='review-quotations'/>
                        <p className="review-text">Tanja was an incredible host! She was quick to respond and provided helpful (and VERY delicious) recommendations for places to visit in Split. Her place was so comfortable and felt like home! Thank you for everything Tanja!</p>
                        <p className="review-author">Laura</p>
                        <div className="review-stars-date">
                            <img src={stars} alt="Stars" className='review-stars'/>
                            <p className="review-date">August 2023</p>
                        </div>
                    </div>
                    <div className="review-card">
                        <img src={quotations} alt="Quotations" className='review-quotations'/>
                        <p className="review-text">We loved staying here because it served as a perfect base for a few days of exploring Split and the surrounding areas. It is easy to take an Uber from the port or bus station and arrive quite close to the apartment- we only needed to walk just a few minutes. Very close by are great restaurants, two grocery stores, and several bakeries; we were so close to the center of town, but tucked into a quiet area so we could sleep without late night noise. The apartment itself is so comfortable, with great air conditioning in the bedroom and family room, and it was sparkling clean. We appreciated being able to do some laundry at this point in our trip - it dried quickly on the lines outside. Visitors could not possibly find a nicer, friendlier host, who will immediately help them feel right at home and is very responsive to questions. We would definitely return when we are in Split again - thank you for such a wonderful spot!</p>
                        <p className="review-author">Juliana</p>
                        <div className="review-stars-date">
                            <img src={stars} alt="Stars" className='review-stars'/>
                            <p className="review-date">July 2023</p>
                        </div>
                    </div>
                    <div className="review-card">
                        <img src={quotations} alt="Quotations" className='review-quotations'/>
                        <p className="review-text">An amazing air bnb! Wow! We had such a great time staying here. Perfect location and brilliant apartment. It had a wonderful balcony to relax on. Tanja is a great host and was very responsive. The apartment is so close to the centre of split.. a short walk. We had a lovely stay!</p>
                        <p className="review-author">Kathryn</p>
                        <div className="review-stars-date">
                            <img src={stars} alt="Stars" className='review-stars'/>
                            <p className="review-date">June 2023</p>
                        </div>
                    </div>
                    <div className="review-card">
                        <img src={quotations} alt="Quotations" className='review-quotations'/>
                        <p className="review-text">The apartment is spacious and well thought through. Exactly as described and more. The location is ideal, away from the crowds but in easy walking distance. Tanja is a star. Great recommendations and handy tips.</p>
                        <p className="review-author">Jo</p>
                        <div className="review-stars-date">
                            <img src={stars} alt="Stars" className='review-stars'/>
                            <p className="review-date">June 2023</p>
                        </div>
                    </div>
                    <div className="review-card">
                        <img src={quotations} alt="Quotations" className='review-quotations'/>
                        <p className="review-text">Beautiful stay in apartment very clean and very cute. Tanja was always available, very accommodating and welcomed us very warmly. In addition , she was very attentive to make our stay go smoothly. We left with very good memories and beautiful photos. A big thank you to Tanja for all this. We recommend Tanja's apartment without hesitation, we will most certainly return there. A big bravo!</p>
                        <p className="review-author">Nathalie</p>
                        <div className="review-stars-date">
                            <img src={stars} alt="Stars" className='review-stars'/>
                            <p className="review-date">May 2023</p>
                        </div>
                    </div>
                    <div className="review-card">
                        <img src={quotations} alt="Quotations" className='review-quotations'/>
                        <p className="review-text">This apartment is in the perfect location to visit the Split area. It’s within easy walking distance of the port and marina etc. The accommodation is very clean and most importantly peaceful at night. The host is very informative. It was our second time at this property and we would be delighted to recommend the apartment and Tanja to you.</p>
                        <p className="review-author">Robert</p>
                        <div className="review-stars-date">
                            <img src={stars} alt="Stars" className='review-stars'/>
                            <p className="review-date">September 2022</p>
                        </div>
                    </div>

                    <div className="review-card">
                        <img src={quotations} alt="Quotations" className='review-quotations'/>
                        <p className="review-text">Excellent accommodation and host, would most definitely recommend!</p>
                        <p className="review-author">Layla</p>
                        <div className="review-stars-date">
                            <img src={stars} alt="Stars" className='review-stars'/>
                            <p className="review-date">August 2022</p>
                        </div>
                    </div>

                    <div className="review-card">
                        <img src={quotations} alt="Quotations" className='review-quotations'/>
                        <p className="review-text">Tanja’s apartment is the perfect location for a couple traveling to Split. It’s within walking distance of the palace, ferry port, and all necessary amenities. The apartment is well-equipped and has a glorious patio for outdoor dining. If you have a vehicle, finding parking is relatively easy. This location works if you are planning to explore the surrounding islands and National parks (like us) or if you want to stay locally and experience all Split has to offer. Tanja was an incredible host and made the best suggestions for our day trips in the surrounding area. (Primosten was awesome!) She’s very knowledgeable and quick to to respond to any questions and will make your stay so much easier.</p>
                        <p className="review-author">Scott</p>
                        <div className="review-stars-date">
                            <img src={stars} alt="Stars" className='review-stars'/>
                            <p className="review-date">July 2022</p>
                        </div>
                    </div>
                    <div className="review-card">
                        <img src={quotations} alt="Quotations" className='review-quotations'/>
                        <p className="review-text">Perfectly located apartment 10 min from the center , quiet with a pleasant terrace. Air conditioning is a big plus . Tanja was a perfect host both for her punctuality and kindness and advice . I highly recommend this apartment.</p>
                        <p className="review-author">Vincent</p>
                        <div className="review-stars-date">
                            <img src={stars} alt="Stars" className='review-stars'/>
                            <p className="review-date">June 2022</p>
                        </div>
                    </div>
                </Slider>
                <div className="reviews-airbnb-link">
                    <p>Reviews are imported from Airbnb. Read more on our <a href="https://www.airbnb.com/rooms/1410677?guests=1&adults=1&s=67&unique_share_id=241305fa-741f-4053-ad73-4a664d474235" target="_blank" rel="noopener noreferrer">Airbnb page</a>.</p>
                </div>
            </div>
        </div>
    );
}

export default Reviews;